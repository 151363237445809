<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import options from "@/cfg/options.json";


export default {
  data() {
    return {
      options: options,
    };
  },
  components: {fieldsRows},
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              fromNumber: {
                type: 'text',
                name: 'From Number',
                colAttrs: {style: 'max-width:378px'},
              },
              toNumber: {
                type: 'text',
                name: 'To Number',
                colAttrs: {style: 'max-width:378px'},
              },
              receivedOn: {
                type: 'text',
                name: 'Received On',
                colAttrs: {style: 'max-width:378px'},
              },
              waitFor: {
                type: 'number',
                name: 'Wait For (seconds)',
                colAttrs: {style: 'max-width:140px'},
              },
              lastMinutes: {
                type: 'number',
                name: 'Last Minutes',
                colAttrs: {style: 'max-width:140px'},
              }
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = {...this.value};
      if(index=="lastMinutes"){
        value  = parseInt(value);
      }
      this.$set(localValue, index, value);
      this.$emit('input', localValue);
     }
  },
};
</script>